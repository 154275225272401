import React from 'react';
import '../styles/TermsOfService.css';

const TermsOfService = () => {
  return (
    <div className="terms-container">
      <h1 className="terms-heading">Terms of Service</h1>
      <div className="terms-content">
        <section className="terms-section">
          <h2 className="section-title">1. Introduction</h2>
          <p className="section-paragraph">
            Welcome to Sync, the music collaboration platform that makes connecting with other artists easy and enjoyable. By using our services, you agree to comply with and be bound by the following terms and conditions.
          </p>
        </section>

        <section className="terms-section">
          <h2 className="section-title">2. User Accounts</h2>
          <p className="section-paragraph">
            To access Sync, you must create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. Sync is not responsible for any unauthorized use of your account.
          </p>
        </section>

        <section className="terms-section">
          <h2 className="section-title">3. Content Ownership</h2>
          <p className="section-paragraph">
            You retain ownership of all original content you create and upload to Sync. By posting your content, you grant Sync a non-exclusive, royalty-free license to use, display, and promote your content on our platform.
          </p>
        </section>

        <section className="terms-section">
          <h2 className="section-title">4. Prohibited Conduct</h2>
          <p className="section-paragraph">
            You agree not to use Sync to upload, post, or share any content that is illegal, offensive, or violates the rights of others. Sync reserves the right to remove any content that violates these terms.
          </p>
        </section>

        <section className="terms-section">
          <h2 className="section-title">5. Termination</h2>
          <p className="section-paragraph">
            Sync reserves the right to terminate your account at any time, without notice, for any reason, including violation of these terms.
          </p>
        </section>

        <section className="terms-section">
          <h2 className="section-title">6. Changes to Terms</h2>
          <p className="section-paragraph">
            Sync may modify these terms at any time. Any changes will be posted on this page, and it is your responsibility to review these terms regularly.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
