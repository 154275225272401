// src/components/Mission.js
import React from 'react';
import '../styles/StaticPages.css';

const Mission = () => {
  return (
    <div className="static-page-container">
      <h1>Mission</h1>
      <p>
        Our mission at Sync is to empower aspiring artists by helping them connect with collaborators, 
        expand their networks, and kickstart their careers. Sync is a platform where artists will 
        find new opportunities, develop their skills, and reach broader audiences within the music world.
      </p>
    </div>
  );
};

export default Mission;
