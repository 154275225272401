import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'



export const Nav = styled.nav`
    //background: #000;
    background: ${({scrollNav}) => (scrollNav ? '#000' : 'transparent')};
    height: 80px;
    margin-top: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

@media screen and (max-width: 960) {
    transition: 0.8 all ease
}
`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
`

export const NavLogo = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  text-decoration: none;

  img {
    margin-right: 10px; /* Space between logo and text */
    width: 36px; /* Adjust based on desired logo size */
    height: 36px; /* Adjust based on desired logo size */
  }
`


export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px)
    {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;


    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const NavItem = styled.li`
    height: 80px;
`

export const NavLinks = styled(LinkS)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none; 
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
 
  &.active {
      border-top: 1px solid #ff5349;
  }
`

export const NavBtn = styled.nav`
  display: flex;
  align-items: center; 

  @media screen and (max-with: 768px) {
    display: none;
  }
`
export const NavBtnLinkCreateAccount = styled(LinkR)`
  border-radius: 50px;
  background: #FF5349;
  white-space: nowrap;
  padding: 10px 22px;
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin: 5px;
  
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #FF5349;
  }
`

export const NavBtnLinkSignIn = styled(LinkR)`
  border-radius: 5px;
  background: #000;
  white-space: nowrap;
  padding: 10px 22px;
  color: #fff;
  font-size: 18px;
  outline: none;
  border: 1px solid #ff5349;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin: 5px;
  
  &:hover {
    transition: all 0.2s ease-in-out;
    color: #FF5349;
    border: 1px solid white;
  }
`

// Icon link for Home and Profile when logged in
export const IconLink = styled(LinkR)`
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  padding: 0 1rem;
  cursor: pointer;
  text-decoration: none;

  &:hover path {
    color: #ff5349;
    transition: 0.3s;
  }

`

