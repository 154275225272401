import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import '../styles/ProfileSetup.css';

const ProfileSetup = () => {
  const [currentStep, setCurrentStep] = useState(1); // New state to track the current step
  const [genres, setGenres] = useState([]);
  const [role, setRole] = useState('');
  const [artistName, setArtistName] = useState('');
  const [lookingFor, setLookingFor] = useState('');
  const [bio, setBio] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [loading, setLoading] = useState(false); // State for loading
  const navigate = useNavigate();
  const auth = getAuth();

  const genreOptions = ["Rock", "Pop", "Hip-Hop", "Electronic", "Jazz", "Classical", "Country"]; // Add more genres as needed

  const handleProfilePictureChange = (e) => {
    setProfilePicture(e.target.files[0]);
  };

  // Handle genre selection
  const toggleGenreSelection = (genre) => {
    if (genres.includes(genre)) {
      setGenres(genres.filter((g) => g !== genre));
    } else {
      setGenres([...genres, genre]);
    }
  };

  const handleSubmit = async () => {
    setLoading(true); // Start loading

    const formData = new FormData();
    genres.forEach((genre) => formData.append('genres[]', genre));
    formData.append('role', role);
    formData.append('artistName', artistName);
    formData.append('lookingFor', lookingFor);
    formData.append('bio', bio);
    formData.append('profilePicture', profilePicture);
    formData.append('city', city);
    formData.append('state', state);

    const user = auth.currentUser;
    if (user) {
      const firebaseUid = user.uid;
      formData.append('firebaseUid', firebaseUid);
    }

    try {
      const response = await axios.post('https://sync-api-troz.onrender.com/api/users', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log("Profile created successfully:", response.data);
      navigate('/home');
    } catch (error) {
      console.error('Error saving profile:', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Function to render the form step based on currentStep
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="form-group">
            <label>Type in your artist name.</label>
            <input
              type="text"
              value={artistName}
              onChange={(e) => setArtistName(e.target.value)}
              required
            />
          </div>
        );
      case 2:
        return (
          <div className="form-group">
            <label>Are you a producer, vocalist, or both?</label>
            <select value={role} onChange={(e) => setRole(e.target.value)} required>
              <option value="">Select...</option>
              <option value="producer">Producer</option>
              <option value="vocalist">Vocalist</option>
              <option value="both">Both</option>
            </select>
          </div>
        );
      case 3:
        return (
          <div className="form-group">
            <label>Looking for...</label>
            <select value={lookingFor} onChange={(e) => setLookingFor(e.target.value)} required>
              <option value="">Select...</option>
              <option value="producers">Producers</option>
              <option value="vocalists">Vocalists</option>
              <option value="both">Both</option>
            </select>
          </div>
        );
      case 4:
        return (
          <div className="form-group">
            <label>What genres are you interested in?</label>
            <div className="genres-list">
              {genreOptions.map((genre) => (
                <button
                  type="button"
                  key={genre}
                  className={`genre-button ${genres.includes(genre) ? 'selected' : ''}`}
                  onClick={() => toggleGenreSelection(genre)}
                >
                  {genre}
                </button>
              ))}
            </div>
          </div>
        );
      case 5:
        return (
          <div className="form-group">
            <label>Bio (Optional)</label>
            <textarea
              value={bio}
              onChange={(e) => setBio(e.target.value)}
            />
          </div>
        );
      case 6:
        return (
          <div className="form-group">
            <label>Profile Picture (Optional)</label>
            <input type="file" onChange={handleProfilePictureChange} />
          </div>
        );
      case 7:
        return (
          <div className="form-group">
            <label>City (Optional)</label>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
        );
      case 8:
        return (
          <div className="form-group">
            <label>State (Optional)</label>
            <input
              type="text"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='profile-setup-page'>
      <h2>Profile Setup</h2>
    <div className="profile-setup-container">
      
      <form className='profile-setup-form' onSubmit={(e) => e.preventDefault()}>
        {renderStep()}

        <div className="form-navigation">
          {currentStep > 1 && (
            <button className='profile-setup-back' type="button" onClick={() => setCurrentStep(currentStep - 1)}>
              Back
            </button>
          )}

          {currentStep < 8 ? (
            <button className='profile-setup-next' type="button" onClick={() => setCurrentStep(currentStep + 1)}>
              Next
            </button>
          ) : (
            <button type="button" onClick={handleSubmit} disabled={loading}>
              {loading ? 'Saving...' : 'Save Profile'}
            </button>
          )}
        </div>
      </form>
    </div>
    </div>
  );
};

export default ProfileSetup;
