import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Navbar.css'; // Import your CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faStar, faCloudUploadAlt, faSearch, faBook } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  return (
    <nav className="bottom-navbar">
      <NavLink exact to="/home" activeClassName="active">
        <FontAwesomeIcon icon={faHome} />
        <span className="nav-label">Home</span>
      </NavLink>
      <NavLink to="/stars" activeClassName="active">
        <FontAwesomeIcon icon={faStar} />
        <span className="nav-label">Stars</span>
      </NavLink>
      <NavLink to="/upload" activeClassName="active">
        <FontAwesomeIcon icon={faCloudUploadAlt} />
        <span className="nav-label">Upload</span>
      </NavLink>
      <NavLink to="/search" activeClassName="active">
        <FontAwesomeIcon icon={faSearch} />
        <span className="nav-label">Search</span>
      </NavLink>
      <NavLink to="/my-discography" activeClassName="active">
        <FontAwesomeIcon icon={faBook} />
        <span className="nav-label">Discography</span>
      </NavLink>
    </nav>
  );
};

export default Navbar;
