// src/components/AboutUs.js
import React from 'react';
import '../styles/StaticPages.css';

const AboutUs = () => {
  return (
    <div className="static-page-container">
      <h1>About Us</h1>
      <p>
        Founded in 2024 by two brothers from Milwaukee, Wisconsin, Sync is the brainchild of George and Teddy. 
        George, the older brother, is an aspiring lyricist and producer with a passion for music. After identifying flaws 
        in existing music collaboration apps, he sought to make a change in the space. Teddy, the younger brother, is a 
        software engineer who excels at bringing big ideas to life through code. Together, they built Sync, an innovative 
        platform designed to transform the way artists collaborate and grow.
      </p>
    </div>
  );
};

export default AboutUs;
