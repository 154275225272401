// src/pages/Cancel.js
import React from 'react';
import '../styles/Cancel.css'

const Cancel = () => {
  return (
    <div className="cancel-container">
      <h1>Payment Canceled</h1>
      <p>Your payment was not completed. You can try again later if you wish to upgrade.</p>
    </div>
  );
};

export default Cancel;
