// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCZAs1HbmLrzqkE-040onkKmfXQXEE_Ls0",
    authDomain: "sync-a2137.firebaseapp.com",
    projectId: "sync-a2137",
    storageBucket: "sync-a2137.appspot.com",
    messagingSenderId: "412209711469",
    appId: "1:412209711469:web:0814bad533f95244057264",
    measurementId: "G-EPTN7KLCZ6"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);


