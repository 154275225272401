// src/pages/index.js
import React, { useState } from 'react';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjOne, homeObjTwo } from '../components/InfoSection/Data';
import SplashNavbar from '../components/Navbar/index.js'; // Splash-specific navbar
import Sidebar from '../components/Sidebar';
import Services from '../components/Services';
import Footer from '../components/Footer';

const Splash = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <SplashNavbar toggle={toggle} /> {/* Use splash-specific navbar */}
      <HeroSection />
      <InfoSection {...homeObjOne} />
      <Services />
      <InfoSection {...homeObjTwo} />
      <Footer />
    </>
  );
};

export default Splash;
