import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../styles/Stars.css'; // Import your CSS styles

const SERVER_URL = 'https://sync-api-troz.onrender.com'; // Update with your actual server URL
const socket = io(SERVER_URL); // Initialize the socket connection

const Stars = () => {
  const [starEvents, setStarEvents] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const auth = getAuth();
  const limit = 10; // Number of items to load per request
  const observer = useRef();
  const lastTrackElementRef = useRef();
  const loadingRef = useRef(false); // Track if fetchStarEvents is running
  const navigate = useNavigate(); // Initialize navigate

  const fetchStarEvents = async (skip = 0) => {
    try {
      if (loadingRef.current) return; // Prevent multiple concurrent fetches

      const user = auth.currentUser;
      if (user) {
        loadingRef.current = true; // Set loadingRef to true before fetching
        setLoading(true);

        const response = await axios.get(`${SERVER_URL}/api/uploads/stars`, {
          params: {
            firebaseUid: user.uid,
            limit,
            skip,
          },
        });

        if (response.data.length < limit) {
          setHasMore(false);
        }

        // Ensure no duplicate entries are added
        const newEvents = response.data.filter(
          (newEvent) => !starEvents.some((existingEvent) => existingEvent._id === newEvent._id)
        );

        // Only update the state if there are new events
        if (newEvents.length > 0) {
          setStarEvents((prevEvents) => [...prevEvents, ...newEvents]);
        }

        setLoading(false);
        loadingRef.current = false; // Set loadingRef to false after fetching
      }
    } catch (error) {
      console.error('Error fetching star events:', error);
      setLoading(false);
      loadingRef.current = false; // Ensure loadingRef is reset even on error
    }
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      // Join the user's room using their UID
      socket.emit('join', user.uid);

      // Listen for new star events
      socket.on('newMessage', (message) => {
        alert(`${message.sender.artistName} wants to collaborate with you!`);
        setStarEvents((prevEvents) => {
          const existingEventIndex = prevEvents.findIndex(
            (event) => event.messageId && event.messageId._id === message._id
          );

          if (existingEventIndex !== -1) {
            const updatedEvents = [...prevEvents];
            updatedEvents[existingEventIndex].messageId = message;
            return updatedEvents;
          } else {
            return [
              ...prevEvents,
              {
                trackId: message.trackId,
                starredByUser: message.sender,
                messageId: message,
                starredAt: new Date(),
              },
            ];
          }
        });
      });

      socket.on('collaborationAccepted', (conversation) => {
        alert('A collaboration offer was accepted! A new chat is available.');
      });

      fetchStarEvents();
    }

    return () => {
      socket.off('newMessage');
      socket.off('collaborationAccepted');
    };
  }, [auth]);

  useEffect(() => {
    if (loading || !hasMore) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loadingRef.current) {
        fetchStarEvents(starEvents.length);
      }
    });

    if (lastTrackElementRef.current) {
      observer.current.observe(lastTrackElementRef.current);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [loading, hasMore, starEvents]);

  const handleAccept = async (messageId) => {
    try {
      await axios.post(`${SERVER_URL}/api/messages/accept`, { messageId });
      alert('Collaboration accepted!');
      
      setStarEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.messageId && event.messageId._id === messageId
            ? { ...event, messageId: { ...event.messageId, status: 'accepted' } }
            : event
        )
      );
    } catch (error) {
      console.error('Error accepting collaboration:', error);
    }
  };

  const handleDeny = async (messageId) => {
    try {
      await axios.post(`${SERVER_URL}/api/messages/deny`, { messageId });
      alert('Collaboration denied.');
      setStarEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.messageId && event.messageId._id === messageId
            ? { ...event, messageId: { ...event.messageId, status: 'denied' } }
            : event
        )
      );
    } catch (error) {
      console.error('Error denying collaboration:', error);
    }
  };

  return (
    <div className="stars-page-container">
      <div className="stars-page-title">Your Stars</div>
      {starEvents.length > 0 ? (
        <div className="starred-tracks-list-container">
          {starEvents.map((event, index) => (
            <div
              key={event._id}
              className="starred-track-item"
              ref={index === starEvents.length - 1 ? lastTrackElementRef : null}
              style={{ cursor: 'pointer' }} // Add a pointer cursor for clickable items
            >
              <img
                src={event.trackId.coverPhoto}
                alt={event.trackId.title}
                className="track-cover-image"
              />
              <div className="track-details-container">
                <div className="track-title">{event.trackId.title}</div>
                <div className="track-detail">{event.trackId.genre}</div>
                <div className="track-detail">Starred By: {event.starredByUser.artistName}</div>
                <div className="track-detail">Starred On: {new Date(event.starredAt).toLocaleDateString()}</div>
                {event.messageId && event.messageId.message ? (
                  <>
                    {/* <div className="collaboration-message">{event.messageId.message}</div> */}
                    {event.messageId.status === 'pending' && (
                      <div className="button-container">
                        <button onClick={() => handleAccept(event.messageId._id)}>Accept</button>
                        <button onClick={() => handleDeny(event.messageId._id)}>Deny</button>
                      </div>
                    )}
                    {event.messageId.status !== 'pending' && (
                      <p
                        className={
                          event.messageId.status === 'accepted'
                            ? 'status-accepted'
                            : 'status-denied'
                        }
                      >
                        Collaboration {event.messageId.status}
                      </p>
                    )}
                  </>
                ) : (
                  <div className="collaboration-message">No collaboration message available.</div>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-stars-message">You haven't received any stars yet.</div>
      )}
      {loading && <div className="loading-message">Loading more stars...</div>}
    </div>
  );
};

export default Stars;
