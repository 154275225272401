import React from 'react';
import '../styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h1 className="privacy-heading">Privacy Policy</h1>
      <div className="privacy-content">
        <section className="privacy-section">
          <h2 className="section-title">1. Introduction</h2>
          <p className="section-paragraph">
            At Sync, we respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our services.
          </p>
        </section>

        <section className="privacy-section">
          <h2 className="section-title">2. Information We Collect</h2>
          <p className="section-paragraph">
            We collect information you provide directly, such as your name, email address, and content you upload. We also collect data about your usage of the platform, including interactions with other users and preferences.
          </p>
        </section>

        <section className="privacy-section">
          <h2 className="section-title">3. How We Use Your Information</h2>
          <p className="section-paragraph">
            We use your information to provide and improve our services, personalize your experience, and communicate with you about updates and promotions. We do not sell your personal information to third parties.
          </p>
        </section>

        <section className="privacy-section">
          <h2 className="section-title">4. Data Security</h2>
          <p className="section-paragraph">
            We take data security seriously and use industry-standard measures to protect your information. However, no system is completely secure, and we cannot guarantee the absolute security of your data.
          </p>
        </section>

        <section className="privacy-section">
          <h2 className="section-title">5. Changes to This Policy</h2>
          <p className="section-paragraph">
            We may update this Privacy Policy from time to time. Any changes will be posted on this page, and your continued use of Sync signifies your acceptance of the updated policy.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
