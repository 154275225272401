import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { FaEllipsisV, FaThumbsUp, FaPlay } from 'react-icons/fa'; // Import the three dots icon
import '../styles/Profile.css';

const Discography = ({ handleTrackClick }) => { // Ensure handleTrackClick is passed as a prop
  const [uploads, setUploads] = useState([]);
  const [userData, setUserData] = useState({
    artistName: '',
    profilePicture: '',
    bio: '',
  });
  const [loading, setLoading] = useState(true);
  const [showOptions, setShowOptions] = useState(null); // State to track the currently clicked three dots menu

  const auth = getAuth();

  useEffect(() => {
    const fetchUserDetails = async (firebaseUid) => {
      try {
        const userResponse = await axios.get(`https://sync-api-troz.onrender.com/api/users/firebase/${firebaseUid}`);
        setUserData(userResponse.data);

        const uploadsResponse = await axios.get(`https://sync-api-troz.onrender.com/api/uploads?firebaseUid=${firebaseUid}`);
        setUploads(uploadsResponse.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const firebaseUid = user.uid;
        fetchUserDetails(firebaseUid);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  // Function to handle deleting a track
  const handleDeleteTrack = async (trackId) => {
    const firebaseUid = auth.currentUser?.uid;
    if (window.confirm('Are you sure you want to delete this track?')) {
      try {
        await axios.delete('https://sync-api-troz.onrender.com/api/uploads/delete-track', {
          data: { trackId, firebaseUid }, // Pass trackId and firebaseUid in the request body
        });
        alert('Track deleted successfully');
        setUploads((prevUploads) => prevUploads.filter((upload) => upload._id !== trackId)); // Remove the deleted track from the state
      } catch (error) {
        console.error('Error deleting track:', error);
        alert('Failed to delete track');
      }
    }
  };

  const handleLikeTrack = async (trackId) => {
    const firebaseUid = auth.currentUser?.uid;
  
    try {
      await axios.post('https://sync-api-troz.onrender.com/api/uploads/like', {
        trackId,
        firebaseUid,
      });
  
      // Update the state to reflect the new like count
      setUploads((prevUploads) =>
        prevUploads.map((upload) =>
          upload._id === trackId
            ? { ...upload, likes: (upload.likes || 0) + 1, likedBy: [...(upload.likedBy || []), firebaseUid] }
            : upload
        )
      );
  
      alert('Track liked successfully!');
    } catch (error) {
      console.error('Error liking track:', error);
      alert('An error occurred while liking the track.');
    }
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="discography-container">
      {/* Profile Banner at the top */}
      <div
        className="profile-banner"
        style={{
          backgroundColor: userData.profilePicture ? '#000' : '#000',
        }}
      >
        {userData.profilePicture && (
          <img
            src={userData.profilePicture} // Directly use the S3 URL
            alt="Profile"
            className="banner-image"
          />
        )}
        <div className="banner-text">
          <h2 className="artist-name">{userData.artistName}</h2>
        </div>
      </div>

      <h1 className='discography-header'>Discography</h1>
      <div className="discography-card-container">
  {uploads.length > 0 ? (
    uploads.map((upload) => (
      <div
        key={upload._id}
        className="upload-item"
        onClick={() => handleTrackClick(upload)} // Ensure clicking triggers handleTrackClick
      >
        <div className='upload-item-container'>
          <img src={upload.coverPhoto} alt="cover-photo" />
          <div className='discography-upload-details'>
            <h3>{upload.title}</h3>
            <p className="discography-genre">{upload.genre}</p>
            
            {/* Display likes and plays */}
            <div className="likes-plays-section">
              <span className="plays-count">
                <FaPlay className="play-icon" style={{marginRight: '5px'}} /> {upload.plays || 0}
              </span>
              <span
                className={`likes-count ${upload.likedBy && upload.likedBy.includes(auth.currentUser?.uid) ? 'liked' : ''}`}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the track click event
                  handleLikeTrack(upload._id);
                }}
              >
                <FaThumbsUp className="like-icon" style={{marginRight: '5px'}}/> {upload.likes || 0}
              </span>
            </div>
          </div>

          {/* Three dots icon for more options */}
          <div className="three-dots-menu" onClick={(e) => { e.stopPropagation(); setShowOptions(showOptions === upload._id ? null : upload._id); }}>
            <FaEllipsisV />
          </div>

          {/* Show the delete option if this track's menu is open */}
          {showOptions === upload._id && (
            <div className="options-menu" onClick={(e) => e.stopPropagation()}>
              <button onClick={() => handleDeleteTrack(upload._id)}>Delete Track</button>
            </div>
          )}
        </div>
      </div>
    ))
  ) : (
    <div className='no-uploads-msg'>You don't have any tracks yet</div>
  )}
</div>

    </div>
  );
};

export default Discography;
