// src/pages/Success.js
import React from 'react';
import '../styles/Success.css'

const Success = () => {
  return (
    <div className="success-container">
      <h1>Payment Successful!</h1>
      <p>Thank you for upgrading to premium! Enjoy your new features.</p>
    </div>
  );
};

export default Success;
