import React, { useState } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import '../styles/Upload.css';

const genresList = ['Rock', 'Pop', 'Hip-Hop', 'Electronic', 'Jazz', 'Classical', 'Country', 'Blues'];

const Upload = () => {
  const [file, setFile] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [title, setTitle] = useState('');
  const [genre, setGenre] = useState('');
  const [trackType, setTrackType] = useState('');
  const [loading, setLoading] = useState(false);
  const auth = getAuth();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCoverPhotoChange = (e) => {
    setCoverPhoto(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file || !title || !genre || !trackType) {
      alert('Please fill in all required fields.');
      return;
    }

    if (!file.name.match(/\.(mp3|wav|flac|aac|ogg|wma|m4a|amr|opus|aiff|mid|midi)$/)) {
      alert('Invalid audio file format. Please upload a supported file.');
      return;
    }

    if (coverPhoto && !coverPhoto.name.match(/\.(jpg|jpeg|png|gif|webp|bmp|tiff|heic|ico|raw|svg)$/)) {
      alert('Invalid cover photo format. Please upload a supported image file.');
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      alert('User is not authenticated. Please log in and try again.');
      return;
    }

    const firebaseUid = user.uid;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('title', title);
    formData.append('genre', genre);
    formData.append('trackType', trackType);
    formData.append('firebaseUid', firebaseUid);
    if (coverPhoto) {
      formData.append('coverPhoto', coverPhoto);
    }

    setLoading(true);

    try {
      const response = await axios.post('https://sync-api-troz.onrender.com/api/uploads', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('File and cover photo uploaded successfully!');
      console.log(response.data);
    } catch (error) {
      console.error('Error uploading file and cover photo:', error);

      if (error.response) {
        switch (error.response.status) {
          case 400:
            alert(error.response.data.message || 'Bad Request. Please check your input and try again.');
            break;
          case 401:
            alert('Unauthorized. Please log in again.');
            break;
          case 403:
            alert(error.response.data.message || 'You have reached your upload limit. Upgrade to premium to upload more tracks.');
            break;
          case 413:
            alert('File size too large. Please upload a smaller file.');
            break;
          case 500:
            alert('Server error. Please try again later.');
            break;
          default:
            alert('Unexpected error occurred. Please try again.');
        }
      } else if (error.request) {
        alert('No response from the server. Please check your internet connection.');
      } else {
        alert('An error occurred while uploading. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="upload-container">
      <h2>Upload Music</h2>
      <form onSubmit={handleSubmit} className="upload-form">
        <div className="form-group-upload">
          <label className="upload-label">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="upload-input"
          />
        </div>

        <div className="form-group-upload">
          <label className="upload-label">Track Type</label>
          <select
            value={trackType}
            onChange={(e) => setTrackType(e.target.value)}
            required
            className="upload-input"
          >
            <option value="">Select Track Type</option>
            <option value="vocals">Vocals</option>
            <option value="beat">Beat</option>
            <option value="both">Vocals & Beat</option>
          </select>
        </div>

        <div className="form-group-upload">
          <label className="upload-label">Genre</label>
          <select
            value={genre}
            onChange={(e) => setGenre(e.target.value)}
            required
            className="upload-input"
          >
            <option value="">Select Genre</option>
            {genresList.map((genre) => (
              <option key={genre} value={genre}>
                {genre}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group-upload">
          <label className="upload-label">Upload File</label>
          <input type="file" accept=".mp3,.wav,.flac,.aac,.ogg,.wma,.m4a,.amr,.opus,.aiff,.mid,.midi" onChange={handleFileChange} />
        </div>

        <div className="form-group-upload">
          <label className="upload-label">Upload Cover Photo</label>
          <input type="file" accept=".jpg,.jpeg,.png,.gif,.webp,.bmp,.tiff,.heic,.ico,.raw,.svg" onChange={handleCoverPhotoChange} />
        </div>

        <button type="submit" className="upload-button" disabled={loading}>
          {loading ? 'Uploading...' : 'Upload'}
        </button>
      </form>

      {loading && <div className="loader"></div>}
    </div>
  );
};

export default Upload;
