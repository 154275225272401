// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

const ProtectedRoute = () => {
  const auth = getAuth();
  const user = auth.currentUser; // Check if the user is authenticated

  return user ? <Outlet /> : <Navigate to="/login" />; // If authenticated, render the route. Otherwise, redirect to login.
};

export default ProtectedRoute;
