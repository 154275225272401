// OtherUserDiscography.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'; // Import useParams to get the user ID from the URL
import { getAuth } from 'firebase/auth';
import { FaThumbsUp, FaPlay, FaStar } from 'react-icons/fa'; // Import the three dots icon

import '../styles/Profile.css';

const OtherUserDiscography = ({ handleTrackClick }) => {
  const [uploads, setUploads] = useState([]);
  const [userData, setUserData] = useState({
    artistName: '',
    profilePicture: '',
    bio: '',
  });
  const [loading, setLoading] = useState(true);

  const auth = getAuth()

  const { id } = useParams(); // Get the userId from the URL

  useEffect(() => {
    const fetchDiscographyForOtherUser = async (firebaseUid) => {
      try {
        // Send firebaseUid as a query parameter
        const response = await axios.get(`https://sync-api-troz.onrender.com/api/users/discography?firebaseUid=${firebaseUid}`);
        setUserData(response.data.user);
        setUploads(response.data.uploads);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching other artist data:', error);
        setLoading(false);
      }
    };
  
    if (id) {
      fetchDiscographyForOtherUser(id); // Pass the firebaseUid (id from useParams)
    }
  }, [id]);

  const handleLikeTrack = async (trackId) => {
    const firebaseUid = auth.currentUser?.uid; // Ensure the user is authenticated
  
    try {
      await axios.post('https://sync-api-troz.onrender.com/api/uploads/like', {
        trackId,
        firebaseUid,
      });
  
      // Update the state to reflect the new like count
      setUploads((prevUploads) =>
        prevUploads.map((upload) =>
          upload._id === trackId
            ? { ...upload, likes: (upload.likes || 0) + 1, likedBy: [...(upload.likedBy || []), firebaseUid] }
            : upload
        )
      );
  
      alert('Track liked successfully!');
    } catch (error) {
      console.error('Error liking track:', error);
      alert('An error occurred while liking the track.');
    }
  };  

  const handleStarTrack = async (trackId, trackUserId) => {
    const currentUserId = auth.currentUser?.uid;
  
    if (trackUserId === currentUserId) {
      alert("You can't star your own track.");
      return;
    }
  
    try {
      await axios.post('https://sync-api-troz.onrender.com/api/uploads/star', {
        trackId,
        userId: currentUserId,
      });
  
      alert("Track starred successfully!");
  
      // Update the state to reflect the new star count
      setUploads((prevUploads) =>
        prevUploads.map((upload) =>
          upload._id === trackId
            ? { ...upload, stars: (upload.stars || 0) + 1, starredBy: [...(upload.starredBy || []), currentUserId] }
            : upload
        )
      );
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === 'You have already starred a track from this artist'
      ) {
        alert('You already starred a track from this user.');
      } else {
        console.error('Error starring track:', error);
        alert('An error occurred while starring the track.');
      }
    }
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="discography-container">
      {/* Profile Banner at the top */}
      <div className="profile-banner" style={{ backgroundColor: userData.profilePicture ? '#000' : '#000' }}>
        {userData.profilePicture && (
          <img src={userData.profilePicture} alt="Profile" className="banner-image" />
        )}
        <div className="banner-text">
          <h2 className="artist-name">{userData.artistName}</h2>
        </div>
      </div>

      <h1 className='discography-header'>{userData.artistName}'s Discography</h1>
      <div className="discography-card-container">
  {uploads.length > 0 ? (
    uploads.map((upload) => (
      <div
        key={upload._id}
        className="upload-item"
        onClick={() => handleTrackClick(upload)}
      >
        <div className='upload-item-container'>
          <img src={upload.coverPhoto} alt="cover-photo" />
          <div className='discography-upload-details'>
            <h3>{upload.title}</h3>
            <p className="discography-genre">{upload.genre}</p>
            
            {/* Display likes and plays */}
            <div className="likes-plays-section">
              <span className="plays-count">
                <FaPlay className="play-icon" style={{ marginRight: '5px' }} /> {upload.plays || 0}
              </span>
              {/* Star Section */}
              <span
                className="star-section"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the track click event
                  handleStarTrack(upload._id, upload.user);
                }}
              >
                <FaStar
                  className="star-icon"
                  style={{ color: upload.starredBy && upload.starredBy.includes(auth.currentUser?.uid) ? '#ff5349' : '#fff'}}
                />
                {upload.stars || 0}
              </span>
              <span
                className={`likes-count ${upload.likedBy && upload.likedBy.includes(auth.currentUser?.uid) ? 'liked' : ''}`}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the track click event
                  handleLikeTrack(upload._id);
                }}
              >
                <FaThumbsUp className="like-icon" style={{ marginRight: '5px' }} /> {upload.likes || 0}
              </span>

            </div>

          </div>
        </div>
      </div>
    ))
  ) : (
    <div className='no-uploads-msg'>This artist hasn't uploaded any tracks yet</div>
  )}
</div>

    </div>
  );
};

export default OtherUserDiscography;
