import img1 from '../../images/svg-1.svg'
import img2 from '../../images/svg-2.svg'
import HHD from '../../images/hero.jpg'
import RPI from '../../images/rpi.jpg'



export const homeObjOne = 
{
    id: 'mission',
    topLine: 'Kickstart your music career',
    headLine: 'We are dedicated to helping you find artists to team up with.  Bringing people together is the soul of music, and we want to cherish that.',
    description: 'Sign up as a producer, vocalist, or both and start collaborating with artists on the platform!',
    alt: 'Guitarist',
    imgStart: true,
    img: HHD,  
    btnContent: 'Read our mission ->',
    to: '/mission'
}

export const homeObjTwo = 
{
    id: 'discover',
    topLine: 'Create your dream',
    headLine: 'We offer technology that connects you with people that will help you create your dream music!',
    description: 'Sync is made for artists',
    alt: 'link',
    imgStart: false,
    img: RPI, 
    btnContent: 'Discover more',
    to: '/discover'
}


