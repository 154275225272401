import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { io } from 'socket.io-client';
import ChatThread from './ChatThread';
import '../styles/Messages.css'; // Import your CSS styles

const socket = io('https://sync-api-troz.onrender.com'); // Connect to your backend

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null); // Track selected conversation
  const [currentUserArtistName, setCurrentUserArtistName] = useState(''); // State for current user's artistName
  const auth = getAuth();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      socket.emit('join', user.uid);

      socket.on('newMessage', (message) => {
        alert(`${message.sender.artistName} wants to collaborate with you!`);
        setMessages((prevMessages) => [...prevMessages, message]);
      });

      socket.on('collaborationAccepted', (conversation) => {
        alert('A collaboration offer was accepted! A new chat is available.');
        setConversations((prevConversations) => [...prevConversations, conversation]);
      });

      // Fetch existing messages
      const fetchMessages = async () => {
        const response = await axios.get(`https://sync-api-troz.onrender.com/api/messages?firebaseUid=${user.uid}`);
        setMessages(response.data);
      };

      // Fetch existing conversations
      const fetchConversations = async () => {
        const response = await axios.get(`https://sync-api-troz.onrender.com/api/conversations?userId=${user.uid}`);
        setConversations(response.data);
      };

      // Fetch current user's artistName from the database
      const fetchCurrentUserArtistName = async () => {
        try {
          const currentUserResponse = await axios.get(`https://sync-api-troz.onrender.com/api/users/firebase/${user.uid}`);
          setCurrentUserArtistName(currentUserResponse.data.artistName);
        } catch (error) {
          console.error('Error fetching current user:', error);
        }
      };

      fetchMessages();
      fetchConversations();
      fetchCurrentUserArtistName();
    }

    return () => {
      socket.off('newMessage');
      socket.off('collaborationAccepted');
    };
  }, [auth]);

  if (selectedConversation) {
    return (
      <ChatThread
        conversationId={selectedConversation._id}
        participants={selectedConversation.participants.map((p) => p.artistName)}
        onClose={() => setSelectedConversation(null)}
      />
    );
  }

  return (
    <div className='messages-content-container'>
      <h1 className='messages-header'>Messages</h1>
      <div className="messages-container">
        {conversations.length > 0 ? (
          conversations.map((conversation) => {
            // Filter out the current user's artistName from the participants array
            const otherParticipants = conversation.participants
              .filter((p) => p.artistName !== currentUserArtistName)
              .map((p) => p.artistName)
              .join(', ');

            return (
              <div
                key={conversation._id}
                className="conversation"
                onClick={() => setSelectedConversation(conversation)} // Set selected conversation
              >
                <p>Chat with {otherParticipants}</p> {/* Display only the other participant(s) */}
              </div>
            );
          })
        ) : (
          <p className="no-conversations">No active conversations.</p>
        )}
      </div>
    </div>
  );
};

export default Messages;
