import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { io } from 'socket.io-client';
import '../styles/ChatThread.css';

const socket = io('https://sync-api-troz.onrender.com'); // Connect to your backend

const ChatThread = ({ conversationId, participants, onClose }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [otherParticipants, setOtherParticipants] = useState([]); // State to store other participants
  const auth = getAuth();

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(`https://sync-api-troz.onrender.com/api/conversations/${conversationId}`);
        setMessages(response.data.messages);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();

    // Listen for new messages via Socket.io
    socket.on('messageReceived', (message) => {
      if (message.conversationId === conversationId) {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    });

    return () => {
      socket.off('messageReceived');
    };
  }, [conversationId]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const currentUserUid = auth.currentUser?.uid;

        // Fetch current user data from the backend using the Firebase UID
        const response = await axios.get(`https://sync-api-troz.onrender.com/api/users/firebase/${currentUserUid}`);
        const currentUserArtistName = response.data.artistName;

        // Filter out the current user's artistName from the participants list
        const filteredParticipants = participants.filter(participant => participant !== currentUserArtistName);

        // Set the filtered participants in state
        setOtherParticipants(filteredParticipants);
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    fetchCurrentUser();
  }, [participants]);

  const handleSendMessage = async () => {
    const user = auth.currentUser;
    if (newMessage.trim() === '' || !user) return;

    try {
      const response = await axios.post(`https://sync-api-troz.onrender.com/api/conversations/${conversationId}/messages`, {
        sender: user.uid, // Assuming you have Firebase UID as the sender
        content: newMessage,
      });

      setMessages([...messages, response.data]);
      setNewMessage('');
      socket.emit('sendMessage', response.data); // Emit the message to the server
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const currentUserId = auth.currentUser?.uid;

  return (
    <div className="chat-thread">
      <button className="close-button" onClick={onClose}>Back to Conversations</button>
      <h2>Chat with {otherParticipants.join(', ')}</h2> {/* Display only the other participant */}
      <div className="messages-container">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`message ${msg.sender === currentUserId ? 'sent' : 'received'}`} // Correctly check sender against the current user's ID
          >
            <p>{msg.content}</p>
          </div>
        ))}
      </div>
      <div className="message-input-container">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
};

export default ChatThread;
