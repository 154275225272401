import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Firebase Auth
import { FaUser, FaHome } from 'react-icons/fa'; // Import icons for Profile and Settings
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
  IconLink // Assuming you have an IconLink styled component
} from './SidebarElements';

const Sidebar = ({ isOpen, toggle }) => {
  const [hover, setHover] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const mouseEnter = () => {
    setHover(true);
  };
  const mouseLeave = () => {
    setHover(false);
  };

  // Check user authentication state
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user); // Set authentication state
    });

    return () => {
      unsubscribe(); // Clean up the auth listener
    };
  }, []);

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink onClick={toggle} to='mission' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
            Mission
          </SidebarLink>
          <SidebarLink onClick={toggle} to='services' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
            Services
          </SidebarLink>
          <SidebarLink onClick={toggle} to='discover' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
            Discover
          </SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          {isAuthenticated ? (
            // Display Profile and Settings icons when logged in
            <>
              <IconLink to='/home' onClick={toggle} title="Home">
                <FaHome style={{ color: hover ? '#FF5349' : '#fff' }} />
              </IconLink>
              <IconLink to='/profile' onClick={toggle} title="Profile">
                <FaUser style={{ color: hover ? '#FF5349' : '#fff' }} />
              </IconLink>
            </>
          ) : (
            // Display Sign in and Create account buttons when not logged in
            <>
              <SidebarRoute
                to='/login'
                style={{
                  color: hover ? '#FF5349' : 'white',
                  background: 'black',
                  border: '1px solid white',
                  borderRadius: '10px',
                }}
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
              >
                Sign in
              </SidebarRoute>
              <SidebarRoute to='/signup'>Create account</SidebarRoute>
            </>
          )}
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
