// src/components/AuthRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

const AuthRoute = () => {
  const auth = getAuth();
  const user = auth.currentUser; // Check if the user is authenticated

  return user ? <Navigate to="/home" /> : <Outlet />; // Redirect to home if logged in, otherwise render the route
};

export default AuthRoute;
