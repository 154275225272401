import styled from "styled-components";

export const PageContainer = styled.div`
  min-height: 692px;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  background: white;
`

export const Text = styled.div`
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 66px;
`

export const Header = styled.div`
    margin: 38px;
    padding-top: 10px;
    font-size: 50px;
    color: #000;

    @media screen and (max-width: 768px){
        font-size: 27px;
    }
    @media screen and (max-width: 300px){
        margin: 21px;
        font-size: 26px;
    }
`

export const Description = styled.div`
    margin: 15px;
    color: black;
    font-size: 14px;
    font-weight: 700;

    @media screen and (max-width: 300px){
        font-size: 11px;
    }
`

export const FormContainer = styled.div`
    width: 66%;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    @media screen and (max-width: 960px) {
        width: 80%;
  }

    @media screen and (max-width: 768px){
        width: 100%;
    }
`

export const Form = styled.form`
    height: fit-content;
`

export const FormList = styled.ul`
    padding: 0;
    margin: 0;
`

export const FormItem = styled.li`
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 10px;
    display: block;
    position: relative;
    background-color: #000;
`

export const FormInput = styled.input`
        width: 100%;
        border: 0;
        opacity: 0.75;
        height: 50px;
        font-size: 16px;
        color: white;
        box-sizing: border-box;

        ::placeholder {
            color: white;
            opacity: 0.6;
        }
`

export const FormMessage = styled.textarea`
        width: 100%;
        border: 0;
        opacity: 0.75;
        height: 50px;
        font-size: 16px;
        color: white;
        min-height: 150px;
        box-sizing: border-box;

        ::placeholder {
            color: white;
            opacity: 0.6;
        }
`

export const FormSubmit = styled.input`
        color: #000;
        background: #fff;
        font: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        align-items: left;
        justify-content: left;
        border: 1px solid #ff5349;
        float: right;
        border-radius: 4px;

        &:hover {
            color: #fff;
            background: #ff5349;
        }
`