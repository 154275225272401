import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import '../styles/Profile.css';

const genresList = ['Rock', 'Pop', 'Hip-Hop', 'Electronic', 'Jazz', 'Classical', 'Country', 'Blues'];

const Profile = () => {
  const [userData, setUserData] = useState({
    artistName: '',
    role: '',
    genres: [],
    lookingFor: '',
    bio: '',
    profilePicture: '',
    city: '',
    state: ''
  });

  const [profilePictureFile, setProfilePictureFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const fetchUserProfile = async (firebaseUid) => {
      try {
        const response = await axios.get(`https://sync-api-troz.onrender.com/api/users/firebase/${firebaseUid}`);
        setUserData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const firebaseUid = user.uid;
        fetchUserProfile(firebaseUid);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleFileChange = (e) => {
    setProfilePictureFile(e.target.files[0]);
  };

  const handleGenreClick = (genre) => {
    if (!userData.genres.includes(genre)) {
      setUserData({ ...userData, genres: [...userData.genres, genre] });
    }
  };

  const removeGenre = (genre) => {
    setUserData({ ...userData, genres: userData.genres.filter((g) => g !== genre) });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('artistName', userData.artistName);
    formData.append('role', userData.role);
    formData.append('bio', userData.bio);
    formData.append('city', userData.city);
    formData.append('state', userData.state);
    formData.append('lookingFor', userData.lookingFor);

    userData.genres.forEach((genre) => formData.append('genres[]', genre));

    if (profilePictureFile) {
      formData.append('profilePicture', profilePictureFile);
    }

    try {
      const user = auth.currentUser;
      if (user) {
        const firebaseUid = user.uid;
        await axios.put(`https://sync-api-troz.onrender.com/api/users/firebase/${firebaseUid}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        window.alert('Profile updated successfully');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate('/');
    }).catch((error) => {
      console.error('Error logging out:', error);
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='profile-container-container'>
      <div
        className="profile-banner"
        style={{
          backgroundColor: userData.profilePicture ? 'transparent' : '#000', // Black background if no profile picture
        }}
      >
        {userData.profilePicture && (
          <img
            src={userData.profilePicture} // Directly use the S3 URL
            alt="Profile"
            className="banner-image"
          />
        )}
        <div className="banner-text">
          <h2 className="artist-name">{userData.artistName}</h2>
        </div>
      </div>
      <div className='bio-container'>
          <div className='bio-content'>
            <h3 style={{color: '#fff', paddingLeft: '20px'}}>Biography:</h3>
            <h5 className='banner-bio'>{userData.bio}</h5>
          </div>
        </div>
      <div className="profile-container">
        <h1 className='update-profile-header'>Update Profile</h1>
        <form onSubmit={handleSubmit} className="profile-form">
              <div className="form-group">
                <label>Artist Name</label>
                <input
                  type="text"
                  name="artistName"
                  value={userData.artistName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Artist Type</label>
                <select name="role" value={userData.role} onChange={handleChange} required>
                  <option value="">Select Artist Type...</option>
                  <option value="producer">Producer</option>
                  <option value="vocalist">Vocalist</option>
                  <option value="both">Producer and Vocalist</option>
                </select>
              </div>

              <div className="form-group">
                <label>What artists are you looking for?</label>
                <select name="lookingFor" value={userData.lookingFor} onChange={handleChange} required>
                  <option value="">Select...</option>
                  <option value="producers">Producers</option>
                  <option value="vocalists">Vocalists</option>
                  <option value="both">Producers and Vocalists</option>
                </select>
              </div>

              <div className="form-group">
                <label>Genres</label>
                <div className="genres-list">
                  {genresList.map((genre) => (
                    <button
                      type="button"
                      key={genre}
                      className={`genre-button ${userData.genres.includes(genre) ? 'selected' : ''}`}
                      onClick={() => handleGenreClick(genre)}
                    >
                      {genre}
                    </button>
                  ))}
                </div>
                <div className="selected-genres">
                  {userData.genres.map((genre) => (
                    <span key={genre} className="selected-genre">
                      {genre}
                      <button type="button" onClick={() => removeGenre(genre)} className="remove-genre">
                        ×
                      </button>
                    </span>
                  ))}
                </div>
              </div>

              <div className="form-group">
                <label>Bio</label>
                <textarea name="bio" value={userData.bio} onChange={handleChange} />
              </div>

              <div className="form-group">
                <label>Profile Picture</label>
                <input type="file" onChange={handleFileChange} />
              </div>

              <div className="form-group">
                <label>City</label>
                <input type="text" name="city" value={userData.city} onChange={handleChange} />
              </div>

              <div className="form-group">
                <label>State</label>
                <input type="text" name="state" value={userData.state} onChange={handleChange} />
              </div>

              <button type="submit" className="update-profile-button">Update Profile</button>
            </form>

        <button onClick={handleLogout} className="logout-button">Sign Out</button>
      </div>
    </div>
  );
};

export default Profile;



