import React, { useState, useEffect, useRef } from 'react';
import { FaPlay, FaPause, FaCaretDown } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import '../styles/AudioPlayer.css';

const AudioPlayer = ({ track, autoPlay }) => {
  const [isPlaying, setIsPlaying] = useState(autoPlay); 
  const [isExpanded, setIsExpanded] = useState(false);
  const [progress, setProgress] = useState(0); 
  const [currentTime, setCurrentTime] = useState(0); 
  const [duration, setDuration] = useState(0); 
  const audioRef = useRef(null);
  const progressBarRef = useRef(null); 

  useEffect(() => {
    if (track) {
      setIsPlaying(autoPlay);
      if (audioRef.current) {
        audioRef.current.pause(); 
        audioRef.current.load(); 
        setProgress(0);
        setCurrentTime(0);

        if (autoPlay) {
          const playPromise = audioRef.current.play(); 
          if (playPromise !== undefined) {
            playPromise.catch((error) => {
              console.warn('Playback interrupted:', error);
            });
          }
        }
      }
    }
  }, [track, autoPlay]);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.warn('Playback interrupted:', error);
          });
        }
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    const updateProgress = () => {
      if (audioRef.current) {
        const current = audioRef.current.currentTime;
        const total = audioRef.current.duration;
        setCurrentTime(current);
        setDuration(total);
        if (total > 0) {
          setProgress((current / total) * 100);
        }
      }
    };

    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', updateProgress);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', updateProgress);
      }
    };
  }, []);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleArtistClick = () => {
    if (isExpanded) {
      setIsExpanded(false); // Minimize the player if it's expanded
    }
  };
  

  const handleMouseMove = (e) => {
    let clientX = e.clientX; 

    if (e.touches) {
      clientX = e.touches[0].clientX; 
    }

    if (progressBarRef.current) {
      const rect = progressBarRef.current.getBoundingClientRect();
      const offsetX = clientX - rect.left;
      const newProgress = (offsetX / rect.width) * 100;
      const newTime = (newProgress / 100) * duration;

      setProgress(newProgress);
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const handleMouseDown = (e) => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    document.addEventListener('touchmove', handleMouseMove);
    document.addEventListener('touchend', handleMouseUp);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);

    document.removeEventListener('touchmove', handleMouseMove);
    document.removeEventListener('touchend', handleMouseUp);
  };

  const formatTime = (time) => {
    if (isNaN(time)) return '0:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  useEffect(() => {
    const audioPlayerElement = document.querySelector('.audio-player');
  
    let startY;
    let currentY;
    let isSwiping = false;
  
    const handleTouchStart = (e) => {
      startY = e.touches[0].clientY;
      isSwiping = true;
    };
  
    const handleTouchMove = (e) => {
      if (!isSwiping) return;
      currentY = e.touches[0].clientY;
  
      // Calculate swipe distance
      const diffY = startY - currentY;
  
      // Apply a threshold for smooth experience
      if (diffY > 30 && !isExpanded) {
        setIsExpanded(true); // Swipe up to expand
        isSwiping = false;
      } else if (diffY < -30 && isExpanded) {
        setIsExpanded(false); // Swipe down to minimize
        isSwiping = false;
      }
    };
  
    const handleTouchEnd = () => {
      isSwiping = false;
    };
  
    // Attach touch event listeners
    if (audioPlayerElement) {
      audioPlayerElement.addEventListener('touchstart', handleTouchStart);
      audioPlayerElement.addEventListener('touchmove', handleTouchMove);
      audioPlayerElement.addEventListener('touchend', handleTouchEnd);
    }
  
    // Cleanup event listeners on component unmount
    return () => {
      if (audioPlayerElement) {
        audioPlayerElement.removeEventListener('touchstart', handleTouchStart);
        audioPlayerElement.removeEventListener('touchmove', handleTouchMove);
        audioPlayerElement.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [isExpanded]);
  

  return (
    <div className={`audio-player ${isExpanded ? 'expanded' : 'minimized'}`}>
      {!isExpanded ? (
        <div className="audio-player-minimized" onClick={handleExpand}>
          <img src={track.coverPhoto} alt={track.title} className="cover-photo" />
          <div className="track-info">
            <p className="track-title-audio-player">{track.title}</p>
            {/* Make the artist's name clickable using Link */}
            <Link 
              to={`/discography/${track.user.firebaseUid}`} 
              className="track-artist"
              onClick={(e) => e.stopPropagation()} // Prevent expansion when clicking the artist name
            >
              {track.user.artistName}
            </Link>
          </div>
          <button className="play-pause-btn" onClick={(e) => { e.stopPropagation(); togglePlayPause(); }}>
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
          {/* Progress bar */}
          <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${progress}%` }} />
          </div>
        </div>
      ) : (
        <div className="audio-player-expanded">
          <FaCaretDown className="minimize-caret" onClick={handleExpand} />
          <img src={track.coverPhoto} alt={track.title} className="large-cover-photo" />
          <div className="expanded-track-info">
            <p className="track-title-audio-player">{track.title}</p>
            {/* Make the artist's name clickable using Link */}
            <Link 
              to={`/discography/${track.user.firebaseUid}`} 
              className="track-artist"
              onClick={(e) => { 
                e.stopPropagation(); 
                handleArtistClick(); 
              }} // Minimize the player when navigating to the artist's page
            >
              {track.user.artistName}
            </Link>
          </div>
          
          <div 
            className="expanded-progress-bar-container" 
            onMouseDown={handleMouseDown}
            onTouchStart={handleMouseDown}
            ref={progressBarRef}
          >
            <div className="expanded-progress-bar" style={{ width: `${progress}%` }} />
            <div 
              className="progress-thumb"
              style={{ left: `calc(${progress}% - 6px)` }}
            />
          </div>
          <div className="time-info">
            <span className="current-time">{formatTime(currentTime)}</span>
            <span className="duration-time">{formatTime(duration)}</span>
          </div>

          <button className="play-pause-btn expanded-play-pause" onClick={(e) => { e.stopPropagation(); togglePlayPause(); }}>
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
        </div>
      )}
      <audio ref={audioRef} src={track.filepath} />
    </div>
  );
};

export default AudioPlayer;
