import React from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { getAuth } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faCrown } from '@fortawesome/free-solid-svg-icons'; // Import the crown icon

const stripePromise = loadStripe('pk_live_51Q5Ao5DAWlNqErty3EQGGPf8txRBlutgQeQmJ8gYZ3XfPpYzHzXUUEPMDEGxn1a0PEQvhAvvpD3WJMxtcT5xSj6t00GLosaf41'); // Replace with your Stripe publishable key
const auth = getAuth()

const GoPremiumButton = () => {
  const handleUpgrade = async () => {
    try {
      const user = auth.currentUser; // Assume you're using Firebase Auth
      const response = await axios.post('https://sync-api-troz.onrender.com/api/create-checkout-session', {
        firebaseUid: user.uid,
      });

      const sessionId = response.data.sessionId;
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Error creating checkout session:', error);
      alert('Failed to redirect to Stripe checkout.');
    }
  };

  return (
    <button onClick={handleUpgrade} className="go-premium-button">
      {/* Add crown icon here */}
      <FontAwesomeIcon icon={faCrown} className="crown-icon" />
      SyncPro
    </button>
  );
};

export default GoPremiumButton;
