import React from 'react'
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink, SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebsiteRights,
    SocialIcons,
    SocialIconLink
    } from './FooterElements'
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa'
import {animateScroll as scroll} from 'react-scroll'
import logoImage from '../../assets/sync-logo-removebg-preview.png'; // Import the logo image

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop()
    }

  return (
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>
                            About Us
                        </FooterLinkTitle>
                            <FooterLink to='/about'>
                                About Us
                            </FooterLink>
                            <FooterLink to='/mission'>
                                Mission
                            </FooterLink>
                            <FooterLink to='/privacy-policy'>
                                Privacy Policy
                            </FooterLink>
                            <FooterLink to='/terms-of-service'>
                                Terms of Service
                            </FooterLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
                
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>
                            Useful Links
                        </FooterLinkTitle>
                            <FooterLink to='/signup'>
                                Create Account
                            </FooterLink>
                            <FooterLink to='/login'>
                                Sign In
                            </FooterLink>
                            <FooterLink to='/contact'>
                                Contact Us
                            </FooterLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to='/' onClick={toggleHome}>
                        <img src={logoImage} alt="Sync Logo" style={{height: '25px', width: '25px', marginRight: '5px'}}/> {/* Logo image */}
                        Sync
                    </SocialLogo>
                    <WebsiteRights>
                        Sync © {new Date().getFullYear()}
                    </WebsiteRights>
                    <WebsiteRights>
                        All rights reserved.
                    </WebsiteRights>
                    <SocialIcons>
                        <SocialIconLink href='/' target='_blank' aria-label='Instagram'>
                            <FaInstagram />    
                        </SocialIconLink>
                        <SocialIconLink href='/' target='_blank' aria-label='Facebook'>
                            <FaFacebook />    
                        </SocialIconLink>
                        <SocialIconLink href='/' target='_blank' aria-label='Youtube'>
                            <FaYoutube />    
                        </SocialIconLink>
                        <SocialIconLink href='/' target='_blank' aria-label='Twitter'>
                            <FaTwitter />    
                        </SocialIconLink>
                        <SocialIconLink href='/' target='_blank' aria-label='Linkedin'>
                            <FaLinkedin />    
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
